<template>
  <div>
    <img src="../../../public/images/qt/政策@3x.jpg" width="100%" height="100%">
  </div>
</template>

<script>
export default {
  name: "隐私政策"
}
</script>

<style scoped>

</style>